<template>
  <div
    class="h-full w-full flex flex-col items-center justify-center fixed top-0 left-0"
  >
    <p class="text-theme-500 font-semibold text-9xl">
      403
    </p>

    <div>
      <p
        id="pageTitle"
        class="flex items-center space-x-2"
      >
        <font-awesome-icon
          class="text-theme-500 "
          icon="exclamation-triangle"
          size="lg"
        />

        <span
          class="text-xl font-medium"
        >
          {{ t('exceptions.error_texts.403.title') }}
        </span>
      </p>

      <p
        v-if="store.state.auth?.user?.authorizations?.access?.extranet"
      >
        {{ t('exceptions.error_texts.403.link.title') }}

        <a
          :href="proUrl"
          class="app-link"
        >
          {{ t('exceptions.error_texts.403.link.to_extranet_label') }}
        </a>
      </p>

      <app-button
        feature="logout"
        :hide-icon="false"
        emphasis="low"
        class="mt-5"
        @click="logout"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import AppButton from '@shared/components/ui/AppButton.vue'
import useLogout from '@shared/hooks/auth/logout'

const store = useStore()
const { t } = useI18n()
const { logout } = useLogout({
  auth_route_name: 'login',
})

const proUrl = computed(() => (
  import.meta.env.VITE_PRO_URL
))
</script>
