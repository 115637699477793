const state = {
  active: window.navigator.onLine,
}

const mutations = {
  ENABLE(state) {
    state.active = true
  },
  DISABLE(state) {
    state.active = false
  },
}

const actions = {
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
