import { fetchLastTravellerTravellerFolder } from '@shared/http/api'

const state = {
  activeFolder: false,
}

const mutations = {
}

const actions = {
  getActiveFolder({ state }) {
    return new Promise((resolve) => {
      fetchLastTravellerTravellerFolder()
        .then((response) => {
          state.activeFolder = response.data.data
        })
        .finally(() => {
          resolve()
        })
    })
  },
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
