<template>
  <template v-if="loading">
    <the-loading />
  </template>

  <template
    v-else
  >
    <the-header v-if="displayHeader" />

    <main
      :class="`max-w-screen-lg px-4 pb-6 mx-auto ${displayHeader ? 'with-header' : ''} ${displayNavbar ? 'with-navbar' : ''}`"
    >
      <the-error-boundary>
        <the-forbidden
          v-if="displayForbidden"
        />

        <router-view
          v-else
          :key="mainKey"
        />
      </the-error-boundary>
    </main>

    <footer class="sticky bottom-0 w-full z-20">
      <the-navbar v-if="displayNavbar" />
    </footer>

    <!-- Modal are ordered in reverse order of priority -->
    <the-update-privacy-policy-modal
      v-if="displayUpdatePrivacyPolicyModel"
    />

    <the-update-terms-condition-modal
      v-if="displayUpdateTermsConditionModel"
    />

    <the-password-missing-modal
      v-if="displayPasswordMissing"
    />

    <the-credential-not-verified-modal
      v-if="displayCredentialNotVerified"
    />

    <the-geoloc-failed-modal
      v-if="displayGeolocFailed"
    />
  </template>

  <the-flash-list />

  <the-spinner />

  <the-reauthentication-modal v-if="authenticated && store.state.auth.reauthenticationNeeded" />

  <the-update-application-modal v-if="displayUpdateApplicationModal" />
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { computed, watch } from 'vue'
import useSessionExpiration from '@shared/hooks/auth/sessionExpiration'
import TheHeader from '@app/components/layout/TheHeader.vue'
import TheSpinner from '@app/components/layout/TheSpinner.vue'
import TheNavbar from '@app/components/layout/navbar/TheNavbar.vue'
import TheFlashList from '@shared/components/features/flashes/TheFlashList.vue'
import TheReauthenticationModal from '@app/components/layout/modals/TheReauthenticationModal.vue'
import TheUpdateApplicationModal from '@app/components/layout/modals/TheUpdateApplicationModal.vue'
import TheLoading from '@app/components/layout/TheLoading.vue'
import TheErrorBoundary from '@app/components/TheErrorBoundary.vue'
import TheUpdatePrivacyPolicyModal from '@app/components/layout/modals/TheUpdatePrivacyPolicyModal.vue'
import TheUpdateTermsConditionModal from '@app/components/layout/modals/TheUpdateTermsConditionModal.vue'
import TheCredentialNotVerifiedModal from '@app/components/layout/modals/TheCredentialNotVerifiedModal.vue'
import ThePasswordMissingModal from '@app/components/layout/modals/ThePasswordMissingModal.vue'
import TheGeolocFailedModal from '@app/components/layout/modals/TheGeolocFailedModal.vue'
import TheForbidden from '@app/components/layout/errors/TheForbidden.vue'

const store = useStore()
const route = useRoute()
const { t } = useI18n()
useSessionExpiration()

const authenticated = computed(() => store.state.auth.authenticated)
const loading = computed(() => store.state.loading.active)

// ---------- DISPLAY LAYOUT ----------

// Adapt the layout when some errors are displayed
const errorLayout = computed(() => (
  displayForbidden.value
))

const displayForbidden = computed(() => (
  authenticated.value && !store.state.auth.user.authorizations.access.app
))
const displayCredentialNotVerified = computed(() => (
  authenticated.value && !store.state.auth.user.attributes.has_verified_credential
))

const displayPasswordMissing = computed(() => (
  authenticated.value && !store.state.auth.user.attributes.has_password
))

// Navbar not needed while not authenticated
// Or on error layout
const displayNavbar = computed(() => (
  authenticated.value
  && !errorLayout.value
))

const headerNotNeededRoutesNames = [
  'login',
  'register',
]

// Header not needed on some routes
// Or on error layout
const displayHeader = computed(() => (
  !headerNotNeededRoutesNames.includes(route.name)
  && !errorLayout.value
))

// ---------- ROUTER KEY ----------

const mainKey = computed(() => (
  store.state.router.mainKey
))

// ---------- NETWORK ONLINE STATUS ----------

window.addEventListener('offline', () => {
  setTimeout(() => {
    // Wait a little before toggle the online mode,
    // just in case it's a very short time disconnection
    if (!window.navigator.onLine && store.state.online.active) {
      store.commit('online/DISABLE')
    }
  }, 3000)
})

window.addEventListener('online', () => {
  store.commit('online/ENABLE')
})

// Display flash messages when online state changes
watch(() => store.state.online.active, (state) => {
  if (state) {
    store.commit(
      'flashes/ADD_FLASH',
      {
        message: t('common.online.connected'),
        type: 'success',
      },
    )
  } else {
    store.commit(
      'flashes/ADD_FLASH',
      {
        message: t('common.online.disconnected'),
        type: 'danger',
      },
    )
  }
})

// ---------- UPDATE APPLICATION ----------

const displayUpdateApplicationModal = computed(() => (
  store.state.serviceWorker.newWorker && !store.state.serviceWorker.updateDeclined && !store.state.serviceWorker.updateApproved
))

// ---------- UPDATE PRIVATE POLICY ----------

const displayUpdatePrivacyPolicyModel = computed(() => (
  authenticated.value
  && (
    !store.state.auth.user.attributes.privacy_policy_accepted
    || store.state.auth.user.attributes.privacy_policy_version !== store.state.terms.privacyPolicy.attributes.version
  )
))

// ---------- UPDATE TERMS CONDITION ----------

const displayUpdateTermsConditionModel = computed(() => (
  authenticated.value
  && (
    !store.state.auth.user.attributes.terms_condition_accepted
    || store.state.auth.user.attributes.terms_condition_version !== store.state.terms.termsCondition.attributes.version
  )
))

// ---------- GEOLOC FAILED ----------

const displayGeolocFailed = computed(() => (
  store.state.modals.geoloc_failed.display
))
</script>
