<template>
  <div
    v-if="error"
    class="h-full w-full flex flex-col items-center justify-center fixed top-0 left-0"
  >
    <p class="text-theme-500 font-semibold text-9xl">
      {{ $filters.capitalize(t('common.oops')) }}
    </p>

    <div>
      <p
        id="pageTitle"
        class="flex items-center space-x-2"
      >
        <font-awesome-icon
          class="text-theme-500 "
          icon="exclamation-triangle"
          size="lg"
        />

        <span
          class="text-xl"
        >
          {{ t('common.errors.internal') }}
        </span>
      </p>

      <p>
        <router-link
          v-slot="{ navigate }"
          :to="{ name: 'home' }"
          custom
        >
          <a
            class="app-link"
            @click="() => handleRestore(navigate)"
          >
            {{ t('common.back_to_home') }}
          </a>
        </router-link>
      </p>
    </div>
  </div>

  <slot v-else />
</template>

<script setup>
import { ref, onErrorCaptured } from 'vue'
import { useI18n } from 'vue-i18n'

const error = ref(false)
const { t } = useI18n()

onErrorCaptured(() => {
  error.value = true
})

async function handleRestore(callback) {
  await callback()
  error.value = false
}
</script>
