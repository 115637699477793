const state = {
  open: true,
}

const mutations = {
  TOGGLE(state) {
    state.open = !state.open
  },
}

const actions = {
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
