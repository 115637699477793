<template>
  <app-overlay
    :show="show"
    @clicked="handleClose"
  >
    <app-modal
      @closed="handleClose"
    >
      <div
        v-if="success && !store.state.spinner.active"
      >
        <app-heading class="mb-4">
          {{ t('user_credential_verifications.verify.sent.title') }}
        </app-heading>

        <p class="text-sm mb-2">
          {{ t('user_credential_verifications.verify.sent.email_1', { email: credentialSent }) }}
        </p>

        <p class="text-sm mb-3">
          {{ t('user_credential_verifications.verify.sent.email_2') }}
        </p>

        <app-button
          emphasis="low"
          :label="t('user_credential_verifications.verify.sent.again')"
          whitespace-classes="whitespace-normal"
          width="full"
          class="mb-3"
          font-size="sm"
          @click="sendCredentialVerification(credentialSent)"
        />

        <app-button
          emphasis="low"
          :label="t('user_credential_verifications.verify.sent.correct')"
          whitespace-classes="whitespace-normal"
          width="full"
          font-size="sm"
          @click="resetForm"
        />
      </div>

      <div
        v-else-if="!store.state.spinner.active"
      >
        <div>
          <!-- Warning title -->
          <app-heading class="mb-4">
            {{ t('exceptions.error_texts.credential_not_verified.title') }}
          </app-heading>

          <!-- Verification form -->
          <form novalidate>
            <!-- Email -->
            <div class="mb-5">
              <form-group
                name="email"
                rules="email"
                type="email"
                :initial-value="store.state.auth.user.attributes.email"
                margins="mb-1"
                class="with-border-on-control"
              />

              <app-button
                emphasis="low"
                :label="t('exceptions.error_texts.credential_not_verified.verify_mail')"
                whitespace-classes="whitespace-normal"
                font-size="sm"
                @click="sendCredentialVerification(values.email)"
              />
            </div>

            <app-separator
              :text="t('auth.messages.or')"
              class="my-4"
            />

            <!-- Phone -->
            <div>
              <form-group
                name="phone"
                rules="phone"
                type="tel"
                :initial-value="store.state.auth.user.attributes.phone"
                margins="mb-1"
                class="with-border-on-control"
              />

              <app-button
                emphasis="low"
                :label="t('exceptions.error_texts.credential_not_verified.verify_phone')"
                whitespace-classes="whitespace-normal"
                font-size="sm"
                @click="sendCredentialVerification(values.phone)"
              />
            </div>
          </form>
        </div>
      </div>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useForm } from 'vee-validate'

import {
  sendCredentialVerification as apiSendCredentialVerification,
} from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppSeparator from '@shared/components/ui/AppSeparator.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppHeading from '@app/components/ui/AppHeading.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const { t } = useI18n()
const store = useStore()

const show = ref(true)

const { values, setErrors } = useForm()
const credentialSent = ref() // Value of the credential sent for verification

const success = ref(false)

function sendCredentialVerification(value) {
  store.commit('spinner/ENABLE')
  credentialSent.value = value

  // Create a new verification procedure
  apiSendCredentialVerification({
    data: {
      attributes: {
        credential: value,
      },
    },
  }).then(() => {
    success.value = true
  }).catch((e) => {
    if (e.response?.data?.errors) {
      setErrors({ ...e.response.data.errors })
    }
    resetForm()
  }).finally(() => {
    store.commit('spinner/DISABLE')
  })
}

// Reset the initial form state
function resetForm() {
  success.value = false
  credentialSent.value = null
}

function handleClose() {
  show.value = false
}
</script>
