const state = {
  transparentBackground: false,
}

const mutations = {
  SET_TRANSPARENT_BACKGROUND(state, value) {
    state.transparentBackground = value
  },
}

const actions = {
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
