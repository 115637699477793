<template>
  <router-link
    v-slot="{ navigate }"
    :to="{ name: routeName }"
  >
    <div
      class="m-auto flex flex-col justify-center items-center min-h-full"
      @click="() => handleClick(navigate)"
    >
      <app-icon
        v-bind="icon"
      />
    </div>
  </router-link>
</template>

<script setup>
import AppIcon from '@shared/components/ui/AppIcon.vue'

defineProps({
  // AppIcon props
  icon: {
    type: Object,
    default: () => ({}),
  },
  // Name of the vue route used
  routeName: {
    type: String,
    required: true,
  },
})

function handleClick(callback) {
  callback()
  window.scroll({ top: 0, left: 0, behavior: 'smooth' })
}
</script>
