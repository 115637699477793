<template>
  <nav
    class="bg-white shadow-top shadow-theme-500/20"
  >
    <div class="text-center text-xs h-16 max-w-screen-lg mx-auto grid grid-cols-5">
      <nav-item
        v-for="(navItem, i) in navItems"
        :key="i"
        v-bind="navItem"
        class="duration-300 nav-item text-theme-900"
      />
    </div>
  </nav>
</template>

<script setup>
import { ref } from 'vue'

import useResource from '@shared/hooks/resources/resource'
import NavItem from './NavItem.vue'

const {
  getIconFromResourceType,
  EXCURSION_TYPE,
  POI_TYPE,
} = useResource()

const navItems = ref([
  {
    icon: {
      library: 'coolicon',
      name: 'home_alt_outline',
      size: 'text-3xl',
    },
    routeName: 'home',
  },
  {
    icon: {
      ...getIconFromResourceType(EXCURSION_TYPE),
      size: 'text-3xl',
    },
    routeName: 'index-excursions',
  },
  {
    icon: {
      ...getIconFromResourceType(POI_TYPE),
      size: 'text-3xl',
    },
    routeName: 'index-points-of-interest',
  },
  {
    icon: {
      library: 'coolicon',
      name: 'share_outline',
      size: 'text-3xl',
    },
    routeName: 'index-shares',
  },
  {
    icon: {
      library: 'coolicon',
      name: 'hamburger',
      size: 'text-3xl',
    },
    routeName: 'navigation',
  },
])
</script>

<style lang="scss" scoped>
  .nav-item {
    &.router-link-active {
      @apply text-theme-500;
    }
  }
</style>
