<template>
  <div
    class="text-theme-500 h-full w-full flex flex-col items-center justify-center fixed top-0 left-0"
  >
    <app-spinner
      size="3x"
      class="mb-5"
    />
    {{ text }}
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { capitalize } from 'lodash'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import AppSpinner from '@shared/components/ui/AppSpinner.vue'

const { t } = useI18n()
const store = useStore()

// Display a custom or a default text
const text = computed(() => (
  store.state.loading.text ?? capitalize(t('common.loading'))
))
</script>
