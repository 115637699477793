const state = {
  updateDeclined: false,
  updateApproved: false,
  newWorker: null,
}

const mutations = {
  SET_UPDATE_DECLINED(state, value) {
    state.updateDeclined = value
  },
  SET_UPDATE_APPROVED(state, value) {
    state.updateApproved = value
  },
  SET_NEW_WORKER(state, value) {
    state.newWorker = value
  },
}

const actions = {
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
